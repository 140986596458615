import { UiModule } from './../../index';
UiModule
    .directive('iscTranslate', IscTranslateDirective);
IscTranslateDirective.$inject = ['_', '$compile'];
function IscTranslateDirective(_, $compile) {
    var propAttributePrefix = 'iscTranslate';
    var attributesPattern = /^\[([\w-]+)]$/;
    return {
        restrict: 'AE',
        controller: IscTranslateController,
        link: function (scope, element, attr, controller) {
            _.each(Object.getOwnPropertyNames(attr))
                .filter(function (propName) {
                return propName.indexOf(propAttributePrefix) === 0 && propName.length > propAttributePrefix.length;
            })
                .forEach(function (attributeName) {
                var argumentName = attributeName.substr(propAttributePrefix.length);
                var match = attributesPattern.exec(argumentName);
                attr.$observe(attributeName, function (newValue) {
                    if (match) {
                        controller.setTranslateText(argumentName, newValue, function (translated) {
                            attr.$set(match[1], translated);
                        });
                    }
                    else {
                        controller.setLabelArgumentValue(argumentName, newValue);
                    }
                });
            });
            attr.$observe(attr.hasOwnProperty(propAttributePrefix) ? propAttributePrefix : 'text', function (value) {
                controller.setTranslateText('$$default', value, function (translated, attributeName) {
                    if (attributeName) {
                        attr.$set(attributeName, translated);
                    }
                    else {
                        element.html(translated);
                        $compile(element.contents())(scope);
                    }
                });
            });
        },
    };
}
var IscTranslateController = (function () {
    function IscTranslateController(iscTranslate) {
        this.iscTranslate = iscTranslate;
        this.argumentValues = {};
        this.translate = {};
    }
    IscTranslateController.prototype.setTranslateText = function (key, text, update) {
        var attributeName;
        var replaced = text.replace(IscTranslateController.attributesPattern, function (match, attribute) {
            attributeName = attribute;
            return '';
        });
        this.translate[text] = {
            text: replaced,
            attribute: attributeName,
            update: update,
        };
        this.remakeLabels();
    };
    IscTranslateController.prototype.setLabelArgumentValue = function (argumentName, value) {
        this.argumentValues[argumentName.toLowerCase()] = value;
        this.remakeLabels();
    };
    IscTranslateController.prototype.remakeLabels = function () {
        var _loop_1 = function (key) {
            if (!this_1.translate.hasOwnProperty(key)) {
                return "continue";
            }
            var translate = this_1.translate[key];
            if (!translate.text) {
                return "continue";
            }
            this_1.iscTranslate.replaceLabels(translate.text, this_1.argumentValues).then(function (updatedString) {
                translate.update(updatedString, translate.attribute);
            });
        };
        var this_1 = this;
        for (var key in this.translate) {
            _loop_1(key);
        }
    };
    IscTranslateController.$inject = ['iscTranslate'];
    IscTranslateController.attributesPattern = /^\[([\w-]+)]/;
    return IscTranslateController;
}());
export { IscTranslateController };
