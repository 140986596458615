export class ServerConstants {
  communicationChannelConstants = {
    enableOnPublicPage: 'Enable card on the Public page',
    enableChannelOnHomepage: 'Enable card on the Home page',
    pushChannelOnTopOfPage: 'Push this card on the top of the page',
    researchActivityChannelsPriorityMessage: 'note that research activity cards already get priority',
    labelCommunicationCard: 'Engagement card',
    labelEmailNewsletter: 'Newsletter',
    labelCommunicationText: 'E-mail',
    labelResearchActivityChallenge: 'Research activity update',
    labelResearchActivityEmail: 'Research activity e-mail',
    labelResearchActivityReminder: 'Research activity reminder e-mail',
    labelResearchActivityReminderShort: 'reminder e-mail',
    labelResearchActivityIncomplete: 'Research activity incomplete e-mail',
    labelResearchActivityIncompleteShort: 'incomplete e-mail',
    labelResearchActivityChallengeHome: 'Research activity card',
    labelResearchActivityMobileApp: 'Mobile application',
    labelCommunicationNews: 'News',
    labelCommunicationNewsHome: 'Communication news home',
    labelHomePage: 'Home page',
    labelCustomAppNotification: 'Custom app notification',
  };
  stimuliCarouselConstants = {
    maxWidth: 614,
    maxHeight: 768,
    minHeight: 390,
  };
  partnerConstantsConstants = {
    partnerMobileAppName: 'Mobile App',
    partnerMobileAppTestName: 'Mobile App Test',
    partnerOpenRegistrationName: 'Open Registration',
  };
  communicationConstants = {
    stimuliSourceDb: 'db',
    stimuliSourceUpload: 'upload',
    stimuliActionDelete: 'delete',
    stimuliActionIgnore: 'ignore',
    stimuliActionUpload: 'upload',
    newsletterTestTempImage: 'Newsletter_Test_Email_Temp',
    editorImageTmp: 'Editor_Image_Tmp',
  };
  validationConstants = {
    emptyGuid: '00000000-0000-0000-0000-000000000000',
    digitRegex: /^\d+$/,
    maxNumberOfRooms: 10,
    nameMaxLength: 25,
    confirmitidMaxLength: 10,
    decipherIdMaxLength: 70,
    urlMaxLength: 50,
    descriptionMaxLength: 300,
    pagenameMaxLength: 50,
    forumroomNameMaxLength: 50,
    customUrlMaxLength: 2500,
    customUrlMinLength: 4,
    activityMaxLength: 50,
    communicationMessageTitleMaxLength: 150,
    customPagesContentMaxLength: 50000,
    pageContentMaxLength: 4000,
    textareaMessageMaxLength: 4000,
    textareaResearchMessageMaxLength: 1500,
    textareaResearchEmailMessageMaxLength: 1500,
    textareaResearchEmailFooterMaxLength: 1000,
    communicationChannelsTitleMaxLength: 150,
    communicationPlatformNotificationTitleMaxLength: 100,
    backgroundVariableNameMaxLength: 50,
    communicationChannelsFooterTitleMaxLength: 50,
    textareaChannelsMaxLength: 1500,
    communicationChannelsCalltoactionMaxLength: 50,
    conversationTitleMaxLength: 150,
    conversationMessageMaxfiles: 3,
    conversationImageMaxsizeMb: 10,
    conversationVideoMaxsizeMb: 419,
    conversationAttachmentMaxNo: 10,
    conversationStimuliMaxNo: 10,
    communicationChannelsRewardsMaxLength: 50,
    communicationChannelsNewsletterMaxLength: 4000,
    communicationChannelsNewsCardMaxLength: 4000,
    qualActivityTitleMaxLength: 150,
    qualActivityDescriptionTextareaMaxLength: 4000,
    activityCardTeaserTextareaMaxLength: 4000,
    qualActivityCardTeaserTextareaMaxLength: 4000,
    landingpageTitleMaxLength: 50,
    landingpageMessageMaxLength: 4000,
    platformNoticeCustomMaxLength: 400,
    integerMaxValue: 2147483647,
    maxProbeQuestions: 999,
    scoutMaxProbeQuestions: 999,
    conversationCaptionMaxLength: 400,
    hashtagMaxLength: 200,
    tagMaxLength: 400,
    squareNotFoundWrongReferrer: 'Square referrer does not match',
    squareParticipantGuidEmptyValidationMessage: 'SquareParticipantGuid can\'t be empty',
    squareParticipantGuidNotFoundValidationMessage: 'The request is not valid.',
    participantGuidEmptyValidationMessage: 'Participant Guid can\'t be empty',
    rewardWinnerGuidEmptyValidationMessage: 'Guid can\'t be empty',
    usernameMinLength: 5,
    usernameMaxLength: 50,
    usernameIsEmptyValidationMessage: 'User name can\'t be empty.',
    usernameInvalidValidationMessage: 'The following characters are not allowed: &, @, ..., and no multiple spaces.',
    usernameNotUniqueValidationMessage: 'User name already in use.',
    userNameLengthValidationMessage: 'User name should be minimum 5 and maximum 50 characters long.',
    aboutMaxLength: 4000,
    aboutLengthValidationMessage: 'About has to be maximum 4000 characters long.',
    emailMaxLength: 250,
    emailEmptyValidationMessage: 'Email address can\'t be empty.',
    emailInvalidValidationMessage: 'The Email address must be valid.',
    emailLengthValidationMessage: 'Email address has to be maximum 250 characters long.',
    emailRegex: /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/,
    passwordMinLength: 8,
    passwordAdMinLength: 10,
    passwordLowercaseLetterRegex: /\p{Ll}/u,
    passwordUppercaseLetterRegex: /\p{Lu}/u,
    passwordOtherLetterRegex: /\p{Lo}/u,
    passwordDigitRegex: /[0-9]/,
    passwordSpecialCharacterRegex: /[ !"#$%&'()*+,./:;<=>?@[^_`{|}~\-\]]/,
    confirmPasswordNotMatchingValidationMessage: 'The two passwords don\'t match.',
    firstnameMaxLength: 200,
    firstNameEmptyValidationMessage: 'First name can\'t be empty.',
    firstNameLengthValidationMessage: 'First name has to be maximum 200 characters long.',
    lastnameMaxLength: 200,
    lastNameEmptyValidationMessage: 'Last name can\'t be empty.',
    lastNameLengthValidationMessage: 'Last name has to be maximum 200 characters long.',
    fullnameMaxLength: 400,
    fullNameEmptyValidationMessage: 'Full name can\'t be empty.',
    fullNameLengthValidationMessage: 'Full name has to be maximum 200 characters long.',
    fullNameSpecialCharactersValidationMessage: 'Full name cannot contain special characters.',
    phonenumberMaxLength: 50,
    phoneNumberLengthValidationMessage: 'Phone number has to be maximum 50 characters long.',
    addressline1MaxLength: 250,
    addressLine1EmptyValidationMessage: 'Address Line 1 can\'t be empty.',
    addressLine1LengthValidationMessage: 'Address Line 1 has to be maximum 250 characters long.',
    addressLine1SpecialCharactersValidationMessage: 'Address Line 1 cannot contain special characters.',
    addressline2MaxLength: 250,
    addressLine2LengthValidationMessage: 'Address Line 2 has to be maximum 250 characters long.',
    addressLine2SpecialCharactersValidationMessage: 'Address Line 2 cannot contain special characters.',
    zippostalcodeMaxLength: 50,
    zipPostalCodeEmptyValidationMessage: 'ZIP/Postal code can\'t be empty',
    zipPostalCodeLengthValidationMessage: 'Zip / Postal code has to be maximum 50 characters long.',
    zipPostalCodeSpecialCharactersValidationMessage: 'ZipPostalCode cannot contain special characters.',
    cityMaxLength: 200,
    cityEmptyValidationMessage: 'City can\'t be empty',
    cityLengthValidationMessage: 'City has to be maximum 200 characters long.',
    citySpecialCharactersValidationMessage: 'City cannot contain special characters.',
    countryMaxLength: 200,
    countryEmptyValidationMessage: 'Country can\'t be empty',
    countryRequiredValidationMessage: 'Country can\'t be empty.',
    countrySpecialCharactersValidationMessage: 'Country cannot contain special characters.',
    countryNotConfiguredValidationMessage: 'Country isn\'t configured for the current square',
    stateregionMaxLength: 200,
    stateRegionLengthValidationMessage: 'State / Region has to be maximum 200 characters long.',
    stateRegionSpecialCharactersValidationMessage: 'State / Region cannot contain special characters.',
    communicationTitleEmptyValidationMessage: 'Communication title is required.',
    communicationTitleMaxLength: 150,
    communicationTitleLengthValidationMessage: 'Communication title should be between 1 and 150 characters.',
    communicationMessageRequiredMessage: 'Communication message is required',
    communicationMessageLengthValidationMessage: 'Communication message should be between 1 and 150 characters.',
    communicationChannelDateInPastValidationMessage: 'Start date can\'t be in the past',
    researchActivityEmailStartDateBeforeActivityStartDateValidationMessage: 'Start date can\'t be before start date of activity.',
    researchActivityEmailStartDateAfterActivityEndDateValidationMessage: 'Start date can\'t be after end date of activity',
    researchActivityEmailFooterTooLongValidationMessage: 'The text must be less than 1000 characters long.',
    researchActivityEmailNewFooterNoNameValidationMessage: 'A new footer should have a name.',
    researchActivityEmailStartDateEmptyValidationMessage: 'Start date can\'t be empty',
    urlTechnicalMaxLength: 2048,
    basicInfoCannotBeUpdated: 'This user is already migrated to AzureAd and the basic info may not be updated anymore.',
    squareNameMaxLength: 50,
    urlConnectProjectSquareLinkedAlreadyValidationMessage: 'This square has already a connect project linked.',
    urlConnectProjectEmptyValidationMessage: 'The project url is not empty.',
    urlConnectProjectIncorrectValidationMessage: 'The project url is not correct.',
    squareNameEmptyValidationMessage: 'Square name should not be empty.',
    squareNameTooLongValidationMessage: 'Square name has to be maximum 50 characters long.',
    closedOrArchivedSquareShouldHaveClosureMessage: 'Closure message is required.',
    squareNameNotUniqueValidationMessage: 'Square with this name already exists.',
    squareUrlRegex: /^([a-z0-9]*)$/,
    squareUrlEmptyValidationMessage: 'Url should not be empty.',
    squareUrlInvalidValidationMessage: 'Only use lowercase letters and numbers.',
    squareUrlNotUniqueValidationMessage: 'This Url has already been taken by another Square.',
    ssoExternalLoginUrlMaxLength: 200,
    ssoExternalLoginUrlEmptyValidationMessage: 'Url should not be empty.',
    ssoExternalLoginUrlTooLongValidationMessage: 'SUrl has to be maximum 200 characters long.',
    jobIdsEmptyValidationMessage: 'You must fill in at least one Job Id.',
    languageEmptyValidationMessage: 'Language can not be empty.',
    startDateEmptyValidationMessage: 'Start date can not be empty.',
    anonymousAndPublicProfileCantBeEnabledValidationMessage: 'Anonymous participation and Public profile participation can\'t be both enabled at the same time.',
    countriesWithAssignedParticipantsCannotBeRemovedValidationMessage: 'At least one removed country has assigned members.',
    adLoginFlowInvalidSuffixMessage: 'AD login flow suffix is invalid for square.',
    adLandingPageTitleMaxLength: 200,
    adLandingPageTextMaxLength: 500,
    userNotAllowedToUpdateProperty: 'You are not allowed to update this property',
    customUrlAndIntroMutualExclusiveValidationMessage: 'You must specify either an introduction or a custom URL.',
    customUrlLengthValidationMessage: 'Url length between 4 and 2500 characters.',
    customIntroLengthValidationMessage: 'Content length should be smaller than 50000 characters.',
    customUrlInvalidFormatValidationMessage: 'Invalid Url format.',
    researcherFirstNameEmptyValidationMessage: 'You must specify first name of the moderator.',
    researcherLastNameEmptyValidationMessage: 'You must specify last name of the moderator.',
    researcherFirstNameLengthValidationMessage: 'First name length should be between 1 and 200 characters.',
    researcherLastNameLengthValidationMessage: 'Last name length should be between 1 and 200 characters.',
    researcherEmailEmptyValidationMessage: 'You must specify email of the moderator.',
    researcherEmailInvalidFormatValidationMessage: 'Invalid email format',
    researcherEmailLengthValidationMessage: 'Email address of the moderator has to be maximum 250 characters long.',
    researcherPictureEmptyValidationMessage: 'You must upload a photo of the moderator.',
    incentiveInProgressValidationMessage: 'You can\'t disable MyRewards while there are unfinished Rewards!',
    myRewardsPageSavingUpRewardFeatureEnabledValidationMessage: 'My Rewards page can\'t be disabled as long as Saving up Rewards is enabled.',
    faqcategoryNameMaxLength: 100,
    faqquestionTitleMaxLength: 120,
    faqanswerMaxLength: 4000,
    duplicatePageNameValidationMessage: 'Page with this name already exists.',
    homePageSegmentIdNotEmptyValidationMessage: 'Segment Id should be empty for home page.',
    squareGuidNotEmptyValidationMessage: 'SquareGuid should not be empty.',
    squareGuidEmptyValidationMessage: 'Page name should not be empty',
    forgotPassHoursValid: 24,
    changeEmailHoursValid: 24,
    customEmailHoursValid: 1,
    activityQualReplyHoursAfterProbed: 48,
    activitySurveyCompletedHoursToDisplay: 24,
    activityModerationGracePeriodDays: 14,
    activityNameMaxLength: 50,
    raffleRewardDescriptionMaxLength: 50,
    periodicRewardMaxLength: 25,
    activityDescriptionMaxLength: 500,
    activityThemeNameMaxLength: 50,
    activityNameEmptyValidationMessage: 'Activity name is required.',
    activityNameLengthValidationMessage: 'Activity name should be between 1 and 50 characters.',
    activityDescriptionLengthValidationMessage: 'Activity description should be at most 500 characters.',
    childActivityFieldInfoMessage: 'This can only be changed with the parent activity',
    activityFormatEmptyValidationMessage: 'Activity format is required.',
    activityFormatInvalidValidationMessage: 'Activity format is invalid.',
    activityTypeEmptyValidationMessage: 'Activity type is required.',
    activityLanguageEmptyValidationMessage: 'Language is required.',
    activityJobIdInvalidValidationMessage: 'Invalid job assignment.',
    activityNotUniqueValidationMessage: 'Activity with this name already exists.',
    segmentationDoesntExistValidationMessage: 'Not all necessary segmentations exist in the current square.',
    activityTypeChanged: 'Activity type can\'t be changed after creation.',
    activityFormatChanged: 'Activity format can\'t be changed after creation.',
    newActivityCheckMarketDisabledValidationMessage: 'Creating CheckMarket surveys is disabled for this square',
    newActivityDecipherDisabledValidationMessage: 'Creating Decipher surveys is disabled for this square',
    newActivitInstinctDisabledValidationMessage: 'Creating Instinct surveys is disabled for this square',
    activityInstinctSurveyIdNotNumericValidationMessage: 'SurveyId must only contain numeric characters.',
    activityInstinctSurveyIdEmptyValidationMessage: 'SurveyId can not be empty',
    activityGuidNotEmptyValidationMessage: 'Activity Guid can\'t be empty',
    activitySquareJobIdInvalidValidationMessage: 'If saving up is enabled, SquareJob cannot be empty.',
    activityJobIdNonExistingValidationMessage: 'A Job ID is required',
    activityJobInvalidValidationMessage: 'This Job ID is not valid, please select from or add a new Job ID on the Program Info/Selfserve activities credits page in order to save the changes.',
    activityNonExistingSquareJobIdValidationMessage: 'Square JobId does not exist in this square.',
    countryParameterNotCorrectValidationMessage: 'Country parameter is not correct',
    activityAccessChangeValidationMessage: 'Only Human8 users are allowed to change the access list',
    activityResearchMessageNotEmptyMessage: 'Message should not be empty',
    activityResearchMessageTooLongMessage: 'The Description should be at most 4000 characters long',
    activityResearchTitleNotEmptyMessage: 'Title should not be empty',
    activityResearchTitleTooLongMessage: 'The title should be at most 150 characters long',
    activityResearchOneStimuli: 'The communication must contain one stimuli',
    activityResearchStartDateNotValidMessage: 'StartDate is not valid.',
    completionPointsEditIsNotAllowed: 'Completion points can only be changed when activity is draft or scheduled.',
    activityResearchEndDateNotValidMessage: 'EndDate is not valid.',
    activityResearchConfirmitIdPatternValidationMessage: 'A correct ConfirmitID starts with a “p” and is followed by at least 6 digits.',
    activityResearchCheckMarketIdPatternValidationMessage: 'this is not a correct CheckMarket survey id.',
    activityResearchDecipherIdPatternValidationMessage: 'this is not a correct Decipher survey id.',
    activityResearchDecipherIdMaxLength: 'A Decipher survey id has a max length of 70',
    activityThemeEmptyValidationMessage: 'Activity theme should not be empty.',
    activityProfileInlineSurveyUriValidationMessage: 'Invalid profile activity URI. Make sure the ConfirmitID in this URL matches the ConfirmitID you entered above.',
    activityResearchConfirmitIdLengthValidationMessage: 'ConfirmitID should be between 1 and 10 characters.',
    activityResearchDatesDontCompile: 'Start date is set after the end date',
    activityEndDateIsInPast: 'End date is set in the past',
    activityWithDisabledTypeCannotBeReopened: 'This type of activity is no longer available and can not be re-opened.',
    activityWithDisabledTypeInSequenceCannotBeReopened: 'This activity is part of a sequence containing an activity with a type that is no longer available and can not be re-opened.',
    completionPointsNotNegativeValidationMessage: 'Negative completion points is not allowed.',
    completionPointsMustBeFilledInValidationMessage: 'Completion points must be filled in.',
    completionPointsCannotBeConfiguredForScreenerActivityValidationMessage: 'Completion points cannot be configured for a screener activity.',
    completionPointsShouldBeNullWhenSavingUpIsNotEnabled: 'Completion points cannot be configured when Saving Up hasn\'t been enabled yet.',
    completionPointsQuotaFullShouldBeLowerThanQualifiedValidationMessage: 'Completion points for quota full can\'t be higher than completion points for qualified.',
    completionPointsScreenedOutShouldBeLowerThanQualifiedValidationMessage: 'Completion points for screened out can\'t be higher than completion points for qualified.',
    activityResearchChangedStartDateForActiveOrClosed: 'Start date can\'t be changed for active or closed activity.',
    activityResearchChangedStartDateForChild: 'Start date can\'t be changed for child activities.',
    activityResearchChangedEndDateForChild: 'End date can\'t be changed for child activities.',
    activityResearchChangedThemeForChild: 'Theme can\'t be changed for child activities.',
    completionTargetNoDecimal: 'The completion target can\'t be a decimal number\'',
    eurCurrencyCantBeAutomaticApproval: 'Rewards with the EUR currency cannot be automatically approved.',
    savingUpRewardsUnitsRequired: 'To determine the value of a reward, a mapping for at least one unit is required.',
    activityResearchChangedVisibilityForActiveOrClosed: 'Visibility can\'t be changed for active or closed activity.',
    activityResearchChangedContextForActiveOrClosed: 'Context can\'t be changed for active or closed activity.',
    activityResearchChangedContextForReplies: 'Context can\'t be changed if at least one reply exists.',
    activityResearchCompleteCriteriaRequired: 'At least one complete criteria must be checked.',
    activityResearchChangedCompleteCriteriaForActiveOrClosed: 'Complete criterias can\'t be changed for active or closed activity.',
    activityRaffleExecutionDateBeforeEndDate: 'Execution date & time must be after activity end date & time',
    activityCreditsShouldHaveRaffle: 'When adding credits, please make sure one or more period rewards are covering the entire time frame that the activity is active.',
    activityResearchRewardCannotBeRemoved: 'Reward can\'t be removed after activity started',
    activityRaffleExecutionDatePassed: 'Activity can\'t be modified when execution date is in the past',
    activitySurveyLinkChangedForActiveOrClosedActivity: 'Survey link can\'t be changed for active or closed activity.',
    activityCreditsChangedForActiveOrClosedActivity: 'Credits can\'t be changed for active or closed activity.',
    activityCreditsRewardsForActiveOrClosedActivity: 'Rewards can\'t be changed for active or closed activity.',
    activitySurveyIdCannotBeChanged: 'Survey id cannot be changed because this activity was already synchronized.',
    activityNoUpdateIfPublished: 'Activity cannot be updated if it is published',
    activityResearchMustHaveContributionType: 'This research activity must have a contribution type.',
    wordsOfOneLetterLanguages: 'ja,zh',
    moderatorCurationMustBeFalse: 'Moderator Curation has to be false for this activity.',
    moderatorCurationMustBeTrue: 'Moderator Curation has to be true for this activity.',
    activityContributionTypeMustBeDefault: 'The Contribution Type for this activity must be Standard (default) or must have no value.',
    publicUninfluencedActivityMustHaveVisibilityConditionType: 'A public uninfluenced activity must have a visibility condition type.',
    visibilityConditionsMustBeNullForInfluencedActivities: 'Visibility conditions must be null for influenced activities.',
    visibilityConditionDateAndHoursMustHaveValues: 'Visibility condition date and buffer hours must have values',
    visibilityConditionDateMustBeAfterStartDate: 'Visibility condition date must be after the activity\'s start date',
    visibilityConditionDateAndHoursMustBeBeforeEndDate: 'Visibility condition date + buffer hours must end before the activity\'s end date',
    contributionTypeEditIsNotAllowed: 'The contribution type can only be changed when activity is draft or scheduled and only from Standard to Diary.',
    moderatorCurationEditIsNotAllowed: 'Moderator curation can only be changed when activity is draft or scheduled.',
    visibilityConditionTypeEditIsNotAllowed: 'The visibility condition type can only be changed when activity is draft or scheduled.',
    activityAsynchronousQualFormatOnlyReasearchType: 'Activity of the type Qualitative Research can have only the format Research activity.',
    confirmitIdRegex: /^p[0-9]{6,}?$/,
    decipherIdRegex: /^selfserve\/\w+(\/\w+)?\/[a-z0-9_]+$/,
    noDecimalNumberRegex: /^[0-9]*$/,
    reminderEmailHoursValid: 48,
    userNameRegex: /^((?![&|@])(?![ ]{2,})(?![.]{3,})[\p{L}\d\s\|\\<,>.\/?;:'"\]\}\[\{=+-_)(*^%$#!~`])*$/u,
    containsHtmlRegex: /<[a-z][\s\S]*>/,
    containsModeratorRegex: /moderator/,
    squareDesignMaxFilesize: 1048576,
    defaultReminderIncompleteEmailHours: 48,
    minHoursIncompleteEmail: 24,
    maxHoursIncompleteEmail: 48,
    defaultCulture: 'en-US',
    appVersionValidation: /^[0-9]+(\.[0-9]+){2}$/,
    ideaStreamUrlValidation: /^https:\/\/(.+?)\.insitescommunities\.(com|eu)\/?/,
    ideaStreamUrlInMessageValidation: /(href="(https:\/\/(.+?)\.insitescommunities\.(com|eu)\/?.*?)")/,
    externalParticipantWithSsoEmail: 'Cannot create high level external participant without allowed domain',
    externalParticipantWithoutSsoEmail: 'Cannot create low level external participant within allowed domain',
    userMentionRegex: 'data-username=\"(.*?)\"',
    hashtagRegex: '#[A-Za-z0-9]+',
    releaseNotes: 'https://goo.gl/SwFBLD',
    mailSubjectEmpty: 'Subject can not be empty',
    squareParticipantAlreadyExistsValidationMessage: 'Square participant already exists',
    loggedInUserNotAllowedToAddInsitesUserValidationMessage: 'The logged in user does not have the right to add an Insites user.',
    noSuspendReasonProvidedOnSuspendValidationMessage: 'A SuspendReason is required when suspending a participant!',
    noNotSuspendedReasonWhenSuspending: 'Cannot use NotSuspended reason when suspending a participant!',
    participantActivationQuestionsInvalidSquareParticipant: 'Invalid square participant while saving activation questions',
    participantActivationQuestionsEmptyResponses: 'Empty responses while saving activation questions',
    participantActivationQuestionsEmptyResponse: 'At least one empty response while saving activation questions',
    participantActivationQuestionsInvalidQuestion: 'Invalid question for at least one response while saving activation questions',
    segmentNameMaxLength: 100,
    segmentPrecodeMaxLength: 30,
    segmentationNameMaxLength: 100,
    segmentNameRegex: /^[^\[\]]*$/,
    segmentationNameRegex: /^[^\[\]]*$/,
    backgroundvarsNameEmptyWarning: 'Name should not be empty',
    backgroundvarsNameLengthWarning: 'Name is not between 1 and 100 characters long',
    backgroundvarsLabelEmptyWarning: 'Label should not be empty',
    backgroundvarsLabelLengthWarning: 'Label should be at most 25 characters',
    backgroundvarsValueEmptyWarning: 'Value should not be empty',
    backgroundvarsValueLabelEmptyWarning: 'Label should not be empty',
    backgroundvarsValueRegex: /^[a-zA-Z0-9_]+$/,
    backgroundvarsValueWarning: 'Value can only contain alfanumeric values and underscores.',
    backgroundvarsValueUnique: 'Values should be unique',
    backgroundvarsValueLabelLengthWarning: 'Label should be at most 25 characters',
    backgroundvarsInvalidPartnerid: 'Invalid partner',
    archiveSegmentationIsNullMessage: 'Could not find segmentation to be archived',
    archiveSegmentationInvalidTypeMessageFormat: 'Cannot archive segmentation of type ',
    archiveSegmentationIsInUseMessage: 'Cannot archive segmentation which is in use',
    noMemberIdsValidationMessage: 'Please add 1 or more memberIds',
    invalidMemberIdsValidationMessage: 'One or more specified memberIds is not part of this square.',
    noSegmentationsValidationMessage: 'Please add 1 or more segmentations',
    noSegmentationsOrIdsCanBeSpecifiedWhenFilterTypeIsAllValidationMessage: 'No segmentations or member IDs can be specified when filter type \'All\' is selected',
    noSegmentsValidationMessage: 'Please select a segment',
    changedTargetingForCommunicationWithChildActivityMessage: 'Targeting of a child activity can\'t be changed directly',
    randomlySelectedShouldBeGreaterThanStartedSamplesMessage: 'The random selection can’t be lower than the number of members that are \'in progress\' or \'completed\' in your current targeting',
    randomlySelectedShouldBePositive: 'The random selection can\'t be negative.',
    targetingFilterTypeNotSpecifiedValidationMessage: 'Filter type must be specified',
    noIdsCanBeSpecifiedWhenFilterTypeIsBySegmentations: 'No Squareparticipant IDs can be specified when filter type \'By Segmentation\' is selected',
    noSegmentationsCanBeSpecifiedWhenFilterTypeIsByMemberIdValidationMessage: 'No segmentations can be specified when filter type \'By Member ID\' is selected',
    noCommunicationGuidErrorMessage: 'CommunicationGuid is missing',
    noActivityGuidErrorMessage: 'ActivityGuid is missing',
    segmentGuidMissingMessage: 'Please provide a valid segment',
    segmentTargetInvalidErrorMessage: 'Segment target cannot be lower than 0!',
    excludeParticipantsInvitedSinceWeeksMinValueMessage: 'The amount of weeks for exclusion should be at least 1',
    targetingQuotaNull: 'A quota has to be defined when selecting to use one',
    targetingQuotaSegmentationsEmpty: 'No segmentations were provided for the targeting quota',
    targetingQuotaSegmentationsNonExisting: 'One of the segmentations used for the targeting quota is no longer available',
    targetingQuotaNoNesting: 'Nested quotas are not supported, please only provide 1 segmentation',
    targetingQuotaItemCombinationIncorrect: 'The provided targeting quota items do not cover all the possible combinations',
    targetingQuotaRandomlySelectedSumIncorrect: 'The sum of the random amounts for the targeting quota items is not the same as the requested randomly selected',
    featureIsInvalid: 'Feature is invalid, must be one of the available features.',
    featureEnabledForAllSquaresRequired: 'Feature must be enabled for all or specific squares.',
    featureSpecificSquaresListEmpty: 'Specific squares list cannot be empty for feature.',
    featureSpecificSquaresDuplicates: 'Specific squares list cannot contain duplicate square per client.',
    featureSquareInvalid: 'Invalid square for client feature.',
    featureCannotBeDisabled: 'Feature cannot be disabled after enabling.',
    checkMarketMasterKeyRequired: 'You should provide a Masterkey when enabling CheckMarket.',
    checkMarketKeyRequired: 'You should provide a Key when enabling CheckMarket.',
    savingUpRewardsMaxMinUnitsRedeem: 1000000,
    savingUpRewardsUnitLabelMaxLength: 50,
    confirmitDirectLinkRegex: /https?:\/\/go.insites-consulting.com\/wix\/p\d+.aspx/,
    decipherDirectLinkRegex: /https:\/\/insites((emea)|(apac)).decipherinc.com\/survey\/.*/,
    researchActivityDirectLinkRegex: /https?:\/\/[a-zA-Z0-9\.:\-]*\/activity\/[\da-zA-Z\-]+/,
    researchActivityDirectTestLinkRegex: /https?:\/\/[a-zA-Z0-9\.:\-]*\/testactivity\/[\da-zA-Z\-]+/,
    mustNotContainSurveyLinkMessage: 'Direct links to surveys are not allowed.',
    clientLogoMaxSize: 1048576,
    unitsToRedeemGreaterThanOrEqualsMinimimUnitsToRedeemMessage: 'There are insufficient units available to be able to redeem a reward.',
    savingUpRewardsUnitLabelRequired: 'Unit Label must be between 1 and 50 characters.',
    savingUpRewardsMinUnitsRedeemValidation: 'Minimum units to redeem must be between 1 and 1000000.',
    jobIdReminderEmailListPattern: /^\s*[;,]?\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}(\s*[;,\s]\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*\s*[;,]?\s*$/,
    jobIdReminderEmailListMaxLength: 1000,
    jobIdReminderEmailListMaxLengthValidationMessage: 'Email address of the moderator has to be maximum 1000 characters long.',
    jobIdReminderEmailListInvalidFormatValidationMessage: 'Must be a list of valid email addresses seperated by a semicolon',
    programNameCannotBeEmpty: 'Program name should not be empty',
    programNameCannotBeTooLong: 'Program name should be between 1 and 25 characters.',
    programNameAlreadyExists: 'Program with this name already exists.',
    programCreditsPerActivityMustBeBiggerThan0: 'Activity credits must be at least 1.',
    geofencingNotificationTextMaxLength: 200,
  };
  imagesConstants = {
    logoTopBar: 'logo-top-bar',
    adLandingPageLogoTopBar: 'logo-top-bar-landing',
    headerLogo: 'header-logo',
    headerDesktop: 'header-desktop',
    headerMobile: 'header-mobile',
    headerEmail: 'header-email',
    background: 'background',
    adLandingPageBackground: 'background-landing',
    activationEmailImage: 'activation-email-image',
    activationReminderEmailImage: 'activation-reminder-email-image',
    almostInactiveEmailImage: 'almost-inactive-email-image',
    changeEmailImage: 'change-email-image',
    digestEmailImage: 'digest-email-image',
    downloadVoucherEmailImage: 'download-voucher-email-image',
    failedStimuliEmailImage: 'failed-stimuli-email-image',
    forgotPasswordMultipleSquaresEmailImage: 'forgot-password-multiple-squares-email-image',
    forgotPasswordEmailImage: 'forgot-password-email-image',
    incentiveEmailImage: 'incentive-email-image',
    newsletterEmailImage: 'newsletter-email-image',
    probingQuestionsEmailImage: 'probing-questions-email-image',
    reactivationEmailImage: 'reactivation-email-image',
    replyAcceptedEmailImage: 'reply-accepted-email-image',
    replyWithAnswerRequiredEmailImage: 'reply-with-answer-required-email-image',
    researchActivityIncompleteEmailImage: 'research-activity-incomplete-email-image',
    researchActivityInviteEmailImage: 'research-activity-invite-email-image',
    researchActivityReminderEmailImage: 'research-activity-reminder-email-image',
    savingUpRewardsCorrectionEmailImage: 'saving-up-rewards-correction-email-image',
    squareClosedPointsExpirationEmailImage: 'square-closed-points-expiration-email-image',
    welcomeEmailImage: 'welcome-email-image',
    communicationCodeEmailImage: 'communication-code-email-image',
  };
  surveyOutcomeCodeLabelConstants = {
    noneLabel: 'None',
    qualifiedLabel: 'Qualified',
    screenedLabel: 'Screened',
    quotaFullLabel: 'Quota Full',
  };
  squareConstants = {
    uncategorizedThemeName: 'Uncategorized',
    maxReplyLevel: 3,
    corporateWebsite: 'https://www.wearehuman8.com',
    wizardNotPublishedStatus: 'Not published yet',
    surveyNotReadyStatus: 'Publish is enabled as soon as the survey is ready. Please wait.',
    surveyNotReadyStatusNoSignalR: 'Enable the publish button by refreshing the page.',
    corporateChromeDownloadLink: 'softwarecenter:SoftwareID=ScopeId_CABA7887-358B-4A19-A0ED-6035703BBC6D/Application_86a70d5a-c2d0-4e2c-aac4-d41bb9893c8a',
    generalChromeDownloadLink: 'https://www.google.com/chrome/',
    squareUrlCreationHour: 4,
    defaultActivityVisibilityConditionBufferHours: 48,
    userNoAccessMessage: 'The user is not in a valid state',
    interceptHttpErrorsFromOtherEnvironments: false,
    sstFailed: 'Errors occurred during random data generation',
  };
  completionTargetConstants = {
    forumDiscussionMin: 20,
    forumDiscussionMax: 250,
    privateDiscussionMin: 0,
    privateDiscussionMax: 50,
    researchActivityMin: 35,
    researchActivityMax: 1000,
    lifeScenesMin: 0,
    lifeScenesMax: 50,
    lifeSnapshotsMin: 0,
    lifeSnapshotsMax: 250,
  };
  activityFilterConstants = {
    anyAnnotation: 'Any annotation',
    noAnnotation: 'No annotation',
    notes: 'Notes',
    highlights: 'Highlights',
    anyStimuli: 'Any stimuli',
    noStimuli: 'No stimuli',
    image: 'Image',
    video: 'Video',
    anyModerationStatus: 'Any moderation status',
    unmoderated: 'Unmoderated',
    moderatedUpdateRequired: 'Moderated (update required)',
    moderatedAccepted: 'Moderated (accepted)',
    moderatedOther: 'Moderated (other)',
    noTagOptionText: 'No tag',
    noTagOptionGuid: '722e9c45-ff56-4c6e-89d5-63e684bbe3a3',
    noHashtagOptionText: 'No hashtag',
    noHashtagOptionGuid: 'efa6dd60-ccc1-4d2e-b9c6-632774ac4588',
  };
  incentivesConstants = {
    rewardLinkValidityDays: 90,
    fallbackCurrency: 'EUR',
    savinguprewardscorrectionCurrency: 'Points',
    savinguprewardscorrectionCurrencyId: 0,
    savinguprewardscorrectionWarning: 'The total amount available is less than the total amount indicated for the following members',
    savingUpRewardsMinUnitsRedeem: '50',
    savingUpRewardsUnitLabel: 'Points',
  };
  storageConstantsConstants = {
    clientCodeMetadata: 'clientcode',
    isDiscussionNewMetadata: 'isdiscussionnew',
    squareGuidMetadata: 'squareguid',
  };
  featureSettingKeysConstants = {
    checkMarketEnabled: 'checkMarketIsEnabled',
    checkMarketMasterKey: 'checkMarketMasterKey',
    checkMarketKey: 'checkMarketKey',
    savingUpRewardsEnabled: 'savingUpRewardsIsEnabled',
    savingUpRewardsUnitLabel: 'savingUpRewardsUnitLabel',
    savingUpRewardsMinUnitsRedeem: 'savingUpRewardsMinUnitsRedeem',
    savingUpRewardsAutomaticHandlingEnabled: 'savingUpRewardsAutomaticHandlingEnabled',
    savingUpRewardsPointsExpirationEnabled: 'savingUpRewardsPointsExpirationIsEnabled',
    savingUpRewardsPointsExpirationPeriodKey: 'savingUpRewardsPointsExpirationPeriod',
    savingUpRewardsPointsExpirationInCompletesKey: 'savingUpRewardsPointsExpirationInCompletes',
    curationFeedbackCircleEnabled: 'curationFeedbackCircleIsEnabled',
    dividedDiscussionTypesEnabled: 'dividedDiscussionTypesIsEnabled',
    scoutDiscussionTypesEnabled: 'scoutDiscussionTypesIsEnabled',
    discussionNewTypesEnabled: 'discussionNewTypesEnabled',
  };
  completionPointsConstants = {
    forumDiscussionMin: 85,
    forumDiscussionMax: 102,
    privateDiscussionMin: 85,
    privateDiscussionMax: 102,
    researchActivityMin: 0,
    researchActivityMax: 100,
    profileActivityMin: 3,
    profileActivityMax: 4,
    lifeScenesMin: 0,
    lifeScenesMax: 1600,
    lifeSnapshotsMin: 0,
    lifeSnapshotsMax: 500,
    surveyActivityMin: 5,
    surveyActivityMax: 6,
    observationMin: 200,
    observationMax: 240,
  };
  sampleStatusLabelConstants = {
    toDoLabel: 'To Do',
    inProgressLabel: 'In Progress',
    completedLabel: 'Completed',
  };
  registrationStatusConstants = {
    singleOptInNoEmail: 0,
    singleOptIn: 1,
    singleOptInReminder: 2,
    doubleOptIn: 3,
    doubleOptInReminder: 4,
    active: 5,
    unsubscribed: 6,
    anonymized: 7,
  };
  pageTypeConstants = {
    home: 1,
    activities: 2,
    forum: 3,
    ideabox: 4,
    news: 5,
    mainPageCustom: 6,
    faq: 7,
    platformTour: 8,
    contact: 9,
    helpCenterCustom: 10,
    footerCustom: 11,
    incentivesCustom: 12,
    myIncentive: 13,
    rewardPolicy: 14,
    savingUpRewardPolicy: 15,
    myCookieSettings: 16,
    cookiePolicy: 17,
    profile: 18,
  };
  communicationStatusConstants = {
    draft: 0,
    scheduled: 1,
    active: 2,
    closed: 3,
  };
  conversationFocusTypeConstants = {
    text: 1,
    visual: 2,
  };
  selectedSegmentsOptionConstants = {
    none: 0,
    any: 1,
    all: 2,
  };
  notificationCategoryConstants = {
    none: 0,
    activity: 1,
    profile: 2,
    page: 3,
    home: 4,
  };
  sampleStatusConstants = {
    inserted: 0,
    toDo: 2,
    inProgress: 3,
    completed: 4,
  };
  splashMessageTypeConstants = {
    undefined: 0,
    agreement: 1,
    squareClosure: 2,
  };
  sortOrderConstants = {
    first: 1,
    second: 2,
    third: 3,
    fourth: 4,
    fifth: 5,
    sixth: 6,
    seventh: 7,
    eighth: 8,
    ninth: 9,
    tenth: 10,
  };
  platformNoticeTargetTypesConstants = {
    none: 0,
    participant: 1,
    human8: 2,
    moderator: 4,
    observer: 8,
  };
  logSeverityConstants = {
    debug: 0,
    info: 1,
    warning: 2,
    error: 3,
    critical: 4,
  };
  rewardPartnerTypeConstants = {
    none: 0,
    tangoCardNonEur: 1,
    paypal: 2,
    cadora: 3,
    tangoCardEurFrance: 4,
    tangoCardEurGermany: 5,
    tangoCardEurItaly: 6,
    tangoCardEurSpain: 7,
    other: 8,
    emag: 9,
    tremendous: 10,
    panoramaResearch: 11,
    ovation: 12,
    uWinIwin: 13,
    guanaitong: 14,
    giftPay: 15,
    savingUpRewardsCorrection: 99,
  };
  manuallyHandledRewardStatusConstants = {
    notHandled: 0,
    handled: 1,
  };
  activityQualCompleteCriteriaTypeConstants = {
    word: 1,
    image: 2,
    video: 3,
    onlineMedia: 4,
    attachment: 5,
  };
  conversationsDownloadTypeConstants = {
    none: 0,
    text: 1,
    stimuli: 2,
  };
  targetingFilterTypeConstants = {
    none: 0,
    bySegmentations: 1,
    byMemberIds: 2,
    all: 3,
  };
  inactivityStatusConstants = {
    active: 0,
    almostInactive: 1,
    dormant: 2,
    inactive: 3,
  };
  roleConstants = {
    noRoleYet: 0,
    human8: 1,
    observer: 2,
    participant: 3,
    professionalAdmin: 7,
    clientAdmin: 9,
    clientEditor: 10,
    clientApi: 4,
    serviceApi: 5,
    azureDevOps: 8,
    ssoUser: 6,
    all: 1000,
  };
  jobIdStatusConstants = {
    legacy: 0,
    notStarted: 1,
    active: 2,
    elapsed75Perc: 3,
    elapsed90Perc: 4,
    expired: 5,
    paused: 6,
  };
  researcherLoginRedirectTypeConstants = {
    activities: 0,
    activityWizard: 1,
    activityConversation: 2,
  };
  programCreditsTypeConstants = {
    native: 0,
    decipher: 1,
  };
  segmentationTypeConstants = {
    survey: 0,
    manual: 1,
    platformBehavior: 2,
    activityBehavior: 3,
    profile: 4,
  };
  targetingStatusConstants = {
    awaiting: 0,
    inProgress: 1,
    done: 2,
  };
  suspendReasonConstants = {
    notSuspended: -1,
    unknown: 0,
    suspendedPanelParticipant: 1,
    jobIdRemoval: 2,
    notActiveInNavison: 3,
    suspendedLowQuality: 4,
    suspendedOther: 5,
    blackListedFraudulent: 6,
    blacklistedSpam: 7,
    blacklistedBot: 8,
    blacklistedOther: 9,
    rightToBeForgotten: 10,
    sourceConsentExpiration: 11,
    switchingLanguage: 12,
  };
  dataFormatTypeConstants = {
    json: 0,
    noJson: 1,
    xml: 2,
  };
  raffleTypeConstants = {
    instant: 1,
    periodic: 2,
    manual: 3,
    savingUp: 4,
  };
  clientStatusConstants = {
    active: 1,
    inactive: 2,
    disabled: 3,
  };
  channelTypeConstants = {
    none: 0,
    communicationCard: 1,
    emailNewsletter: 2,
    communicationText: 4,
    researchActivityChallenge: 5,
    researchActivityEmail: 6,
    researchActivityReminder: 7,
    researchActivityIncomplete: 8,
    researchActivityChallengeHome: 9,
    incentiveCard: 10,
    researchActivityMobileApp: 11,
    communicationNews: 12,
    notification: 13,
    notificationUpdate: 14,
    communicationNewsHome: 15,
    discussionThread: 16,
    pageIntro: 17,
    customAppNotification: 18,
    digestEmail: 19,
    pageCta: 20,
    profilePage: 21,
  };
  targetingTypeConstants = {
    activity: 0,
    communication: 1,
  };
  squareParticipantSourceConstants = {
    screener: 1,
    import: 2,
    individual: 3,
    navisionSync: 4,
  };
  activitySequenceRoleConstants = {
    unsequenced: 0,
    parent: 1,
    child: 2,
  };
  clientVideoStorageConstants = {
    azureMediaServices: 0,
    mux: 1,
    human8MediaService: 2,
  };
  clientVideoQualityConstants = {
    low: 0,
    medium: 1,
    high: 2,
  };
  generalCommunicationTypeConstants = {
    other: 0,
    email: 1,
    homePage: 2,
    customAppNotification: 3,
  };
  platformNoticeTypeConstants = {
    none: 0,
    defaultMaintenance: 1,
    customNotice: 2,
  };
  realtimeMessagingConstants = {
    signalrConnected: 0,
    platformHealthDummy: 1,
    communicationSampleComplete: 2,
    profileActivitiesChanged: 3,
    progressChanged: 4,
    totalCreditsChanged: 5,
    userNotification: 6,
    userNotificationAdmin: 7,
    todoActivities: 8,
    timeLineChanged: 9,
    suspendedNotification: 10,
    showVideoThumbnail: 11,
    showPhotoThumbnail: 12,
    updateAttachmentUrl: 13,
    showEngagementCardVideoThumbnail: 14,
    discussionChanged: 15,
    discussionNewChanged: 16,
    discussionModerationDataChanged: 17,
    discussionActivityComplete: 18,
    forumConversationChanged: 19,
    loungeTopicAdded: 20,
    replyAdded: 21,
    pagesChanged: 22,
    userLoggedOut: 23,
    squareStatusChanged: 24,
    prepareSurveyFinished: 25,
    targetingNumbersUpdated: 26,
    forcedLogout: 27,
    stimulusTranscriptionStatusChanged: 28,
  };
  appearanceConstants = {
    forum: 1,
    chat: 2,
    titles: 3,
    centered: 4,
  };
  publishResearchActivityOptionConstants = {
    hardLaunch: 1,
    softLaunch: 2,
  };
  consentConstants = {
    asynchronousResearch: 0,
  };
  cardTypeConstants = {
    none: 0,
    manualRewards: 1,
    automaticSavingUp: 2,
    manualSavingUp: 3,
  };
  ideaStreamProjectTargetConstants = {
    customPage: 0,
    engagementMessage: 1,
    newsletter: 2,
  };
  activityContextTypeConstants = {
    public: 0,
    private: 1,
  };
  squareActivityTypeConstants = {
    none: 0,
    confirmitSurvey: 1,
    qualitativeResearch: 2,
    checkMarketSurvey: 3,
    publicQualitativeResearch: 4,
    privateQualitativeResearch: 5,
    decipherSurvey: 6,
    publicScoutResearch: 7,
    privateScoutResearch: 8,
    discussion20Research: 9,
    decipherDiarySurvey: 11,
    observation: 12,
    instinctSurvey: 13,
  };
  hangfireQueueConstants = {
    default: 0,
    critical: 1,
    recurrent: 2,
    targeting: 3,
    exports: 4,
  };
  winnerStatusConstants = {
    approved: 1,
    notApproved: 2,
    notified: 3,
    detailsConfirmed: 4,
    offeredToRedeem: 5,
  };
  stimuliFilterOptionConstants = {
    anyStimuli: -1,
    noStimuli: 0,
    image: 1,
    video: 2,
  };
  conversationSortOptionConstants = {
    chronological: 0,
    mostRecent: 1,
    mostLiked: 2,
  };
  squareConfigTypeConstants = {
    none: 0,
    adLoginDisabledSocial: 1,
    adLoginForceMigration: 2,
    qrCodeImagesInsteadOfAppLinks: 3,
  };
  activityContributionTypeConstants = {
    standard: 0,
    diary: 1,
  };
  surveyPreparationStatusConstants = {
    notStarted: 0,
    preparing: 1,
    finished: 2,
  };
  notificationTypeConstants = {
    profileActivityAvailable: 0,
    qualResearchActivityAvailable: 1,
    quantResearchActivityAvailable: 2,
    qualResearchAlmostClosed: 3,
    quantResearchAlmostClosed: 4,
    discussionMention: 5,
    qualResearchActivityUpdate: 6,
    discussionReply: 7,
    discussionLike: 8,
    forumMention: 9,
    forumReply: 10,
    forumLike: 11,
    platformNotification: 12,
    nextActivityInSequenceLaunched: 13,
    discussionActivityComplete: 14,
    savingUpRewardsEarned: 15,
    newProbingQuestion: 16,
    newOptionalProbingQuestion: 17,
    savingUpRewardsCorrectionApplied: 18,
    rewardsAvailableForRedemption: 19,
    discussionMentions: 2000,
    discussionReplies: 200,
    discussionLikes: 20,
    discussionMentionReplyLike: 1110,
    discussionMentionRepliesLike: 1210,
    discussionMentionReplyLikes: 1120,
    discussionMentionRepliesLikes: 1220,
    discussionMentionsReplyLike: 2110,
    discussionMentionsRepliesLike: 2210,
    discussionMentionsReplyLikes: 2120,
    discussionMentionsRepliesLikes: 2220,
    discussionMentionReply: 1100,
    discussionMentionReplies: 1200,
    discussionMentionsReply: 2100,
    discussionMentionsReplies: 2200,
    discussionMentionLike: 1010,
    discussionMentionLikes: 1020,
    discussionMentionsLike: 2010,
    discussionMentionsLikes: 2020,
    discussionReplyLike: 110,
    discussionRepliesLike: 210,
    discussionReplyLikes: 120,
    discussionRepliesLikes: 220,
    forumMentions: 4000,
    forumReplies: 400,
    forumLikes: 40,
    forumMentionReplyLike: 3330,
    forumMentionRepliesLike: 3430,
    forumMentionReplyLikes: 3340,
    forumMentionRepliesLikes: 3440,
    forumMentionsReplyLike: 4330,
    forumMentionsRepliesLike: 4430,
    forumMentionsReplyLikes: 4340,
    forumMentionsRepliesLikes: 4440,
    forumMentionReply: 3300,
    forumMentionReplies: 3400,
    forumMentionsReply: 4300,
    forumMentionsReplies: 4400,
    forumMentionLike: 3030,
    forumMentionLikes: 3040,
    forumMentionsLike: 4030,
    forumMentionsLikes: 4040,
    forumReplyLike: 330,
    forumRepliesLike: 430,
    forumReplyLikes: 340,
    forumRepliesLikes: 440,
    moreSavingUpRewardsEarned: 6000,
    newLoungeTopic: 6001,
    discussionMentionForAdmin: 7000,
    newReplyWithAnswerRequired: 7001,
    replyAccepted: 7002,
    failedStimuliUpload: 7003,
    squareParticipantAlmostInactive: 7004,
  };
  squareActivityStatusConstants = {
    draft: 0,
    scheduled: 1,
    active: 2,
    closed: 3,
    archived: 4,
  };
  moderationStatusOptionConstants = {
    anyModerationStatus: -1,
    unmoderated: 0,
    moderatedUpdateRequired: 1,
    moderatedAccepted: 2,
    moderatedOther: 3,
  };
  raffleStatusConstants = {
    winnersNotDrawn: 1,
    winnersDrawn: 2,
    winnersPartial: 3,
  };
  inSitesSquareLogoutRedirectTypeConstants = {
    squareInfo: 0,
    activities: 1,
    squareLogin: 2,
    activityWizard: 3,
    activityConversation: 4,
  };
  conversationElementTypeConstants = {
    none: 0,
    topic: 1,
    question: 2,
    answer: 3,
    reply: 4,
    individualUpdatePost: 5,
  };
  socialLoginTypeConstants = {
    none: 0,
    facebook: 1,
    google: 2,
    apple: 3,
    weChat: 4,
  };
  faqCategoryTypeConstants = {
    none: 0,
    manualRewards: 1,
    savingUpRewards: 2,
    manualSavingUpRewards: 3,
  };
  surveyOutcomeCodeConstants = {
    none: 0,
    qualified: 1,
    screened: 2,
    quotaFull: 3,
  };
  searchTypeConstants = {
    client: 0,
    program: 1,
    square: 2,
    activity: 3,
    network: 4,
  };
  stimuliUploadStateConstants = {
    empty: 0,
    uploading: 1,
    encoding: 2,
    valid: 3,
    invalid: 4,
    invalidAndNotified: 5,
    transcribing: 6,
  };
  squareActivityApprovalListConstants = {
    manual: 0,
    automatic: 1,
  };
  squareActivityFormatConstants = {
    none: 0,
    screener: 1,
    profile: 2,
    research: 3,
  };
  conversationStimuliTypeConstants = {
    image: 0,
    video: 1,
    youtube: 2,
    vimeo: 3,
  };
  conversationStimuliStatusConstants = {
    saved: 0,
    removed: 1,
    new: 2,
    savingAsDraft: 3,
  };
  accountTypeConstants = {
    squareAccount: 0,
    socialLogin: 1,
  };
  researchMethodConstants = {
    quantitative: 1,
    qualitative: 2,
  };
  annotationFilterOptionConstants = {
    anyAnnotation: -1,
    noAnnotation: 0,
    notes: 1,
    highlights: 2,
  };
  recruitmentPartnerCallbackTypeConstants = {
    pixel: 0,
    redirect: 1,
  };
  dubStackConstants = {
    dubUs: 0,
    dubEu: 1,
  };
  recruitmentPartnerLinkTypeConstants = {
    closed: 0,
    open: 1,
  };
  languageWordsCompositionKindConstants = {
    wordsOfOneLetter: 0,
    wordsOfMoreLetters: 1,
  };
  activitySequenceErrorsConstants = {
    none: 0,
    periodicReward: 1,
  };
  areaConstants = {
    undefined: 0,
    mainPages: 1,
    helpCenter: 2,
    footer: 3,
    incentives: 4,
    splash: 5,
  };
  targetingFilterOptionConstants = {
    none: 1,
    any: 2,
    all: 3,
  };
  activityVisibilityConditionTypeConstants = {
    default: 0,
    acceptedContribution: 1,
  };
  conversationsDownloadAvailabilitiesConstants = {
    none: 0,
    text: 2,
    stimuli: 4,
  };
  exportTypeConstants = {
    undefined: 0,
    healthReport: 1,
    onboardingReport: 2,
    communicationData: 3,
    conversation: 4,
    qualConversation: 5,
    qualConversationStimuli: 6,
    userQualConversations: 7,
    userQualConversationsStimuli: 8,
    manualRewardTemplate: 9,
    membersImportTemplate: 10,
    members: 11,
    savingUpRewards: 12,
    powerpointOneColumn: 13,
    powerpointWorkshopDeck: 14,
    manuallyHandledRewards: 15,
    geofencingLocationImportTemplate: 16,
    geofencingLocationExport: 17,
  };
  logTypeConstants = {
    log: 0,
    audit: 1,
    mail: 2,
    export: 3,
    manualReward: 4,
    upload: 5,
  };
  conversationQuestionTypeConstants = {
    none: 0,
    text: 1,
    image: 2,
    video: 3,
    imageAndVideo: 4,
    attachment: 5,
    freestyle: 6,
  };
  squareActivationQuestionTypeConstants = {
    text: 0,
    checkbox: 1,
    multiCheckbox: 2,
    radio: 3,
    list: 4,
    listCheckbox: 5,
  };
  emailTypeConstants = {
    activation: 0,
    activationReminderSoi: 1,
    forgotPassword: 2,
    changeEmail: 3,
    unsubscribe: 4,
    activationReminderDoi: 5,
    welcome: 6,
    forgotPasswordMultipleSquares: 7,
    activationNonParticipant: 8,
    activationReminderSoiNonParticipant: 9,
    activationReminderDoiNonParticipant: 10,
    confirmCustomEmail: 11,
    activityInvite: 100,
    activityReminder: 101,
    activityIncomplete: 102,
    rewardWinnerNotification: 103,
    newsletter: 104,
    tangocardBalanceInsufficient: 105,
    tangocardBalanceWarning: 106,
    digest: 107,
    probingQuestions: 108,
    savingUpRewardsCorrectionNotification: 109,
    rewardWinnerDownloadVoucher: 110,
    incentiveSupport: 111,
    replyWithAnswerRequired: 112,
    replyAccepted: 113,
    almostInactive: 114,
    failedStimuliUpload: 115,
    incentiveReminder: 116,
    closingSquare: 200,
    archivingSquare: 201,
    deletingSquare: 202,
    deletedSquare: 203,
    jobIdAlmostExpired: 300,
    jobIdExpired: 301,
    programCreditsAlmostExpired: 400,
    programCreditsMonthlyReminder: 401,
  };
  operationTypeConstants = {
    delete: -1,
    unchanged: 0,
    create: 1,
    update: 2,
  };
  squareStatusConstants = {
    draft: 0,
    scheduled: 1,
    active: 2,
    closed: 3,
    archived: 4,
    deleted: 5,
  };
  activityVisibilityTypeConstants = {
    influenced: 0,
    uninfluenced: 1,
    private: 2,
  };
  featureConstants = {
    none: 0,
    checkMarket: 1,
    savingUpRewards: 2,
    dividedDiscussionTypes: 3,
    decipher: 4,
    scoutDiscussionTypes: 5,
    quota: 6,
    confirmit: 7,
    curationFeedbackCircle: 9,
    analysisAndReporting: 10,
    discussion20New: 11,
    activityTargetCompletion: 13,
    consentMapping: 14,
    soiTargeting: 15,
    noUnreadConversations: 16,
    accessData: 18,
    mediaGallery: 19,
    decipherDiary: 21,
    captcha: 23,
    observation: 24,
    activityJobIdEverywhere: 25,
    azureAdB2CLogin: 26,
    footerRestrictions: 27,
    syncQualButtonHomepage: 28,
    chatGpt: 29,
    pulse: 30,
    customDiscussionImport: 41,
    instinctSurveyTool: 42,
  };
  hostingEnvironmentConstants = {
    localhost: 0,
    sentia: 1,
    azure: 2,
  };
  squareParticipantActionConstants = {
    none: 0,
    shouldLoginNextTime: 1,
  };
  fileTypeConstants = {
    image: 1,
    video: 2,
    attachment: 3,
  };
  stimulusTypeConstants = {
    photo: 0,
    video: 1,
    youtube: 2,
    vimeo: 3,
    attachment: 4,
  };
  activitySequenceWarningsConstants = {
    none: 0,
    startDate: 1,
    endDate: 2,
    targeting: 4,
    theme: 8,
    childHasEmailCommunication: 16,
    parentHasEmailCommunication: 32,
    childHasExecutionDateBeforeParentEndDate: 64,
    completeRedirect: 128,
    cardVisibility: 256,
    completionTarget: 512,
  };
  squareParticipantHistoryTypeConstants = {
    none: 0,
    registrationStatus: 1,
    adObjectId: 2,
    email: 3,
    username: 4,
    field: 5,
    migrating: 6,
  };
  messageTypeConstants = {
    engagement: 0,
    researchActivity: 1,
    qualActivity: 2,
    profileActivity: 3,
  };
  redirectForumTypeConstants = {
    singleTopic: 0,
    room: 1,
    newTopics: 2,
  };
  savingUpRewardPageItemTypeConstants = {
    reward: 1,
    redemption: 2,
  };
  discussionSortConstants = {
    chronological: 0,
    mostRecent: 1,
    mostLiked: 2,
  };
  moderationStatusConstants = {
    unmoderated: 0,
    updateRequired: 1,
    accepted: 2,
    other: 3,
  };
  annotationTypeConstants = {
    none: 0,
    hashtag: 1,
    highlight: 2,
    like: 3,
    mention: 4,
    note: 5,
    quote: 6,
    rating: 7,
    tag: 8,
  };
  displayStylesConstants = {
    none: 0,
    greyedOut: 1,
    hidden: 2,
    focused: 4,
    placeHolder: 8,
  };
  questionTypesConstants = {
    text: 1,
    attachment: 2,
    photo: 4,
    video: 8,
    photoVideo: 12,
    freestyle: 15,
  };
}

angular
  .module('insitesApp.core')
  .constant('serverConstants', new ServerConstants());
